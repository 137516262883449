<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'WhatsApp'" :subtitle="$t('generic-str.send')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-7">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs primary-tabs">
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'simple'"
                    data-toggle="tab"
                    aria-expanded="true"
                    :class="{'active': form.type == 'simple'}"
                    @click="changeType('simple')"
                    ><span class="material-symbols-outlined"> edit </span>
                    <span>{{ $t('sms.send-msg.tabs.simple.title') }}</span>
                    <!-- Simples -->
                  </a>
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'contact'"
                    aria-expanded="true"
                    @click="changeType('contacts')"
                    :class="{'active': form.type == 'contacts'}"
                    ><i class="far fa-address-book"></i>
                    <span>{{ $tc('sms.send-msg.tabs.contact.title', 1) }}</span>
                  </a>
                  <!-- Contato -->
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'group'"
                    :class="{'active': form.type == 'groups'}"
                    aria-expanded="true"
                    @click="changeType('groups')"
                    ><span class="material-symbols-outlined"> group </span>
                    <span>{{ $t('sms.send-msg.tabs.groups.title') }}</span>
                    <!-- Grupos -->
                  </a>
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'files'"
                    aria-expanded="true"
                    @click="changeType('files')"
                    ><span class="material-symbols-outlined">
                      file_upload
                    </span>
                    <span>{{ $t('sms.send-msg.tabs.archives.title') }}</span>
                    <!-- Arquivos -->
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div
                        v-if="shortCodes.length > 1"
                        class="form-group row sender-group"
                      >
                        <div class="col-lg-12 template-wrapper">
                          <label class="control-label text-left col-12 col-sm-3"
                            >{{ $t('generic-str.lbl-sender') }}
                            <!-- Remetente -->
                          </label>
                          <select v-model="form.from" class="form-control">
                            <option
                              v-for="shortCode in shortCodes"
                              :key="shortCode.id"
                              :value="shortCode.short_code"
                            >
                              {{ shortCode.short_code }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- <div v-if="form.type != 'simple'" class="form-group row"> -->
                      <div
                        class="form-group row campaign-group"
                        v-if="form.type != 'simple'"
                      >
                        <label class="control-label text-left col-12 col-sm-3">{{
                          $t('sms.send-msg.lbl-campaign')
                        }}</label>
                        <div class="col-md-9">
                          <input
                            v-model="form.name"
                            class="form-control campaign-input"
                          />
                        </div>
                      </div>
                      <div class="tab-content">
                        <div class="tab-pane" id="simple" :class="{'active': form.type == 'simple'}">
                          <div class="form-group row">
                            <label class="control-label text-left col-12">{{
                              $t('sms.send-msg.tabs.simple.lbl-recipient')
                            }}</label>
                            <div class="col-md-12">
                              <textarea
                                v-model="form.to"
                                rows="6"
                                class="form-control"
                                v-bind:class="{
                                  invalid:
                                    submited &&
                                    form.to.length == 0 &&
                                    form.type == 'simple',
                                }"
                                :placeholder="
                                  this.$t(
                                    'sms.send-msg.tabs.simple.lbl-example',
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 template-wrapper">
                              <label class="mr-3 col-12 col-sm-3 pl-0">Template: </label>
                              <template-input
                                v-model="form.template"
                                @input="insertTemplate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="contact" :class="{'active': form.type == 'contacts'}">
                          <div class="form-group row">
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label
                                  class="control-label text-left col-4 pl-0"
                                  >{{
                                    $tc('sms.send-msg.tabs.contact.title', 2)
                                  }}</label
                                >
                                <button
                                  class="btn btn_add"
                                  type="button"
                                  v-modal="{ target: 'contact-send-modal' }"
                                >
                                  <span
                                    class="material-symbols-outlined add_person"
                                  >
                                    person_add
                                  </span>
                                  <!-- Adicionar Contato -->
                                </button>
                                <hr />
                                <b-collapse
                                  id="collapse-1"
                                  visible
                                  class="mt-2 col-lg-12"
                                >
                                  <b-card class="send-group-card">
                                    <div
                                      class="text-center table-placeholder"
                                      v-if="contacts.length == 0"
                                    >
                                      <!-- <i class="fas fa-user font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.contact.lbl-none-contact',
                                          )
                                        }}
                                      </h5>
                                      <!-- Nenhum contato adicionado -->
                                    </div>
                                    <p
                                      class="card-text"
                                      v-if="contacts.length > 0"
                                    >
                                      {{
                                        $t(
                                          'sms.send-msg.tabs.contact.lbl-added-contacts',
                                        )
                                      }}
                                      ({{ contacts.length }})
                                    </p>
                                    <!-- Contatos adicionados -->
                                    <ul
                                      class="ul-list-groups"
                                      v-if="contacts.length > 0"
                                    >
                                      <li
                                        v-for="(contact, i) in contacts"
                                        :key="contact.id"
                                      >
                                        <div
                                          class="
                                            alert
                                            alert-secondary
                                            alert-outline
                                            alert-dismissible
                                            fade
                                            show
                                          "
                                          role="alert"
                                        >
                                          {{ contact.first_name }} /
                                          {{ contact.mobile_number }}
                                          <button
                                            type="button"
                                            class="close"
                                            @click="removeContact(i)"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="fas fa-times"
                                            ></span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 template-wrapper">
                              <label class="mr-3 col-12 col-sm-3 pl-0">Template: </label>
                              <template-input
                                v-model="form.template"
                                @input="insertTemplate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="group" :class="{ active: form.type == 'groups' }">
                          <div class="form-group row">
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label class="control-label text-left">{{
                                  $t('sms.send-msg.tabs.groups.title')
                                }}</label>
                                <button
                                  class="btn btn_add"
                                  type="button"
                                  v-modal="{ target: 'group-send-modal' }"
                                >
                                  <span
                                    class="material-symbols-outlined add_person"
                                  >
                                    group_add
                                  </span>
                                  <!-- Adicionar Grupo -->
                                </button>
                                <hr />
                                <b-collapse
                                  id="collapse-1"
                                  visible
                                  class="mt-2 col-lg-12"
                                >
                                  <b-card class="send-group-card">
                                    <div
                                      class="text-center table-placeholder"
                                      v-if="groups.length == 0"
                                    >
                                      <!-- <i class="fas fa-users font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.groups.lbl-none-group',
                                          )
                                        }}
                                        <!-- Nenhum grupo adicionado -->
                                      </h5>
                                    </div>
                                    <p
                                      class="card-text"
                                      v-if="groups.length > 0"
                                    >
                                      {{
                                        $t(
                                          'sms.send-msg.tabs.groups.lbl-added-groups',
                                        )
                                      }}
                                      ({{ groups.length }})
                                      <!-- Grupos adicionados -->
                                    </p>
                                    <ul
                                      class="ul-list-groups"
                                      v-if="groups.length > 0"
                                    >
                                      <li
                                        v-for="(group, i) in groups"
                                        :key="group.id"
                                      >
                                        <div
                                          class="
                                            alert
                                            alert-secondary
                                            alert-outline
                                            alert-dismissible
                                            fade
                                            show
                                          "
                                          role="alert"
                                        >
                                          {{ group.name }}
                                          <button
                                            type="button"
                                            class="close"
                                            @click="removeGroup(i)"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="fas fa-times"
                                            ></span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 template-wrapper">
                              <label class="mr-3 col-12 col-sm-3 pl-0">Template: </label>
                              <template-input
                                v-model="form.template"
                                @input="insertTemplate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="files">
                          <div class="form-group row">
                            <div
                              v-if="!importing"
                              class="col-md-12 p-0 add-contacts"
                            >
                              <label class="control-label text-left col-12 col-sm-3">{{
                                $t('sms.send-msg.tabs.archives.lbl-add-archive')
                              }}</label>
                              <!-- Adicionar Arquivo -->
                              <input
                                ref="uploadedFile"
                                type="file"
                                accept=".csv"
                                hidden
                                @change="uploadFile"
                              />
                              <div class="col-lg-9 archive-opt">
                                <div class="d-flex align-items-center flex-wrap">
                                  <div class="form-control form-file">
                                    {{ form.file }}
                                  </div>
                                  <button
                                    class="btn btn-import"
                                    type="button"
                                    @click="openFileUpload"
                                  >
                                    <template v-if="form.file">{{
                                      $t(
                                        'sms.send-msg.tabs.archives.lbl-change-archive',
                                      )
                                    }}</template>
                                    <!-- Trocar Arquivo -->
                                    <template v-else>
                                      <span
                                        class="
                                          material-symbols-outlined
                                          icon_title
                                        "
                                        >file_upload</span
                                      ><!-- {{
                                      $t(
                                        'sms.send-msg.tabs.archives.lbl-select-archive',
                                      )
                                    }} --></template
                                    >
                                    <!-- Selecionar Arquivo -->
                                  </button>
                                </div>
                                <!-- Tooltip: Layout do arquivo para importação. -->
                                <a
                                  class="sample-area d-flex align-items-center"
                                  href="/exemplos/example.csv"
                                  download="example.csv"
                                  v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')">
                                    <!-- {{$t('generic-str.example')}} -->
                                    <span class="material-icons-outlined">file_download</span>
                                    example.csv
                                </a>
                              </div>
                            </div>
                            <div v-else class="col-md-6">
                              <div class="row">
                                <div class="col-12">
                                  <small>{{
                                    $t(
                                      'sms.send-msg.tabs.archives.lbl-validating',
                                    )
                                  }}</small>
                                  <!-- O arquivo está sendo validado -->
                                </div>
                              </div>
                              <div class="progress">
                                <div
                                  class="
                                    progress-bar
                                    progress-bar-striped
                                    progress-bar-animated
                                  "
                                  role="progressbar"
                                  :style="`width: ${uploadProgress}%;`"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {{ uploadProgress }}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-lg-12 template-wrapper">
                              <label class="mr-3 col-12 col-sm-3 pl-0">Template: </label>
                              <template-input
                                v-model="form.template"
                                @input="insertTemplate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <div class="row">
                          <div class="col-lg-12" v-if="form.template.body">
                            <label for="sms-body">Preview</label>
                          </div>
                          <div class="col-lg-12" v-if="form.template.body">
                            <div class="whats-container">
                              <div class="whats-preview">
                                <div class="relative">
                                  <img
                                    id="img-preview"
                                    :src="`${form.template.header.example.header_handle[0]}`"
                                    v-if="
                                      form.template.header &&
                                      form.template.header.format === 'IMAGE'
                                    "
                                  />
                                  <i
                                    class="
                                      fas
                                      fa-edit
                                      card-edit-btn
                                      absolute
                                      c-pointer
                                    "
                                    style="right: 10px; top: 10px; color: #000"
                                    @click="
                                      callCrop(
                                        'img-preview',
                                        500,
                                        280,
                                        form.header,
                                      )
                                    "
                                    v-if="
                                      form.template.header &&
                                      form.template.header.format === 'IMAGE'
                                    "
                                  ></i>
                                </div>
                                <p
                                  class="whats-preview-header"
                                  v-if="
                                    form.template.header &&
                                    form.template.header.format === 'TEXT'
                                  "
                                >
                                  {{ form.template.header.text }}
                                </p>
                                <p class="whats-preview-body">
                                  {{ form.template.body }}
                                </p>
                                <p
                                  class="whats-preview-footer"
                                  v-if="form.template.footer"
                                >
                                  {{ form.template.footer.text }}
                                </p>
                              </div>
                              <div class="row" v-if="form.template.buttons">
                                <div
                                  class="col-sm-12"
                                  v-for="(btn, i) in form.template.buttons"
                                  :key="i"
                                >
                                  <button
                                    type="button"
                                    class="btn-link-preview"
                                    v-if="btn.type == 'PHONE_NUMBER'"
                                  >
                                    <i class="fas fa-phone"></i> {{ btn.text }}
                                  </button>
                                  <button
                                    type="button"
                                    class="btn-link-preview"
                                    v-if="btn.type == 'URL'"
                                  >
                                    <i class="fas fa-link"></i> {{ btn.text }}
                                  </button>
                                  <button
                                    type="button"
                                    class="btn-link-preview"
                                    v-if="btn.type == 'QUICK_REPLY'"
                                  >
                                    {{ btn.text }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="form-group">
                        <div
                          class="row"
                          v-for="(p, i) in form.params"
                          :key="p.index"
                        >
                          <div class="col">
                            <div class="relative">
                              <div
                                class="
                                  popup-box-container
                                  dropdown
                                  to-right
                                  var-insede-input
                                "
                                style="margin-left: 5px"
                              >
                                <a href="javascript:void(0)" v-dropdown>
                                  {{
                                    $t(
                                      'sms.send-msg.sms-input-component.lbl-variables',
                                    )
                                  }}
                                  <i
                                    class="fas fa-plus"
                                    v-tooltip.top="
                                      $t(
                                        'sms.send-msg.sms-input-component.lbl-variables',
                                      )
                                    "
                                  ></i>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  x-placement="bottom-end"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    v-for="variable in vars"
                                    :key="variable.value"
                                    @click="addVarParams(variable, i)"
                                    >{{ variable.name }}</a
                                  >
                                </div>
                              </div>
                              <label
                                >{{ $t('generic-str.lbl-param') }}
                                {{ p.index }}</label
                              >
                              <!-- Parâmetro -->
                              <input
                                class="form-control"
                                type="text"
                                v-model="p.text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="col-lg-4 col-md-12 col-sm-12"
                      style="display: none"
                    >
                      <div class="card card-border-info">
                        <div class="card-header p-l-20 p-b-15 p-t-15">
                          {{ $t('options-div.title') }}
                        </div>
                        <div class="card-body">
                          <div class="form-group row">
                            <div class="col-md-12">
                              <div
                                class="
                                  custom-control
                                  custom-checkbox
                                  custom-control-inline
                                  custom-control-inline-send
                                "
                              >
                                <input
                                  class="
                                    tgl tgl-light tgl-primary
                                    calendar-change
                                  "
                                  id="schedule"
                                  type="checkbox"
                                  v-model="form.schedule"
                                  name="schedule"
                                  @change="changeSchedule"
                                />
                                <label class="tgl-btn" for="schedule"></label>
                                <label class="label-send-check">{{
                                  $t('options-div.lbl-schedule')
                                }}</label>
                              </div>
                              <date-picker
                                v-if="form.schedule"
                                v-model="form.datetime"
                                :config="options"
                                class="form-control input-date"
                                :placeholder="$t('generic-str.date-hour')"
                              ></date-picker>
                            </div>
                          </div>
                          <hr class="dashed" />
                          <template-input
                            v-model="form.template"
                            @input="insertTemplate"
                          />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer">
              <div v-if="!this.client" class="card-footer-wrapper">
                <span class="animated-background w-400 h-40 ml-auto"></span>
              </div>
              <div v-else class="card-footer-wrapper">
                <div v-if="this.client.active" class="right-items">
                  <!-- <span class="material-icons title"> image </span>
                  <span class="material-symbols-outlined title attach">
                    attach_file
                  </span>
                  <i class="fas fa-smile title"></i> -->
                  <div class="calendar_form">
                    <date-picker
                      v-if="form.schedule"
                      v-model="form.datetime"
                      :config="options"
                      v-b-tooltip.hover :title="form.datetime"
                      class="form-control input-date"
                      :placeholder="$t('generic-str.date-hour')"
                    ></date-picker>
                  </div>
                  <span class="material-icons text-yup calendar_icon"  @click="this.form.schedule = true">
                    watch_later
                  </span>
                  <button
                    @click="send"
                    type="button"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                      hide: !this.client.active,
                    }"
                    :disabled="isSending || importing"
                    class="btn btn-yup-purple float-right ml-3"
                  >
                    {{ $t('generic-str.send') }}
                  </button>
                  <!-- <b-dropdown
                    @click="send"
                    type="button"
                    right
                    dropup
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                      hide: !this.client.active,
                    }"
                    :disabled="isSending || importing"
                    split
                    variant="yup-purple"
                    :text="$t('generic-str.send')"
                  >
                    <template #button-content>
                      {{ $t('generic-str.send') }}
                    </template>
                    <b-dropdown-item v-model="form.birthDate"
                      >{{ $t('options-div.schedule-delivery') }}
                      <span class="material-icons">
                        watch_later
                      </span></b-dropdown-item
                    >
                  </b-dropdown> -->
                </div>
                <div v-else class="right-items">
                  <div class="alert alert-warning">
                    {{ $t('generic-str.client-inactive') }}
                    <strong
                      ><a
                        href="https://yupchat.typeform.com/to/b0aG4Maq"
                        target="_blank"
                        >{{ $t('generic-str.request-access') }}</a
                      ></strong
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div class="card-body">
                <div v-if="form.template.body" class="relative">
                  <img
                    id="img-preview"
                    :src="`${form.template.header.example.header_handle[0]}`"
                    v-if="form.template.header && form.template.header.format === 'IMAGE'"
                  />
                  <i
                    class="fas fa-edit card-edit-btn absolute c-pointer"
                    style="right: 10px; top: 10px; color: #000"
                    @click="callCrop('img-preview', 500, 280, form.header)"
                    v-if="form.template.header && form.template.header.format === 'IMAGE'"
                  ></i>
                  <p
                    v-if="form.template.header && form.template.header.format === 'TEXT'"
                  >
                    {{ form.template.header.text }}
                  </p>
                  <p v-if="form.template.body">
                    {{ form.template.body }}
                  </p>
                  <p v-if="form.template.footer">
                    {{ form.template.footer.text }}
                  </p>
                  <div class="preview-message-btns" v-if="form.template.buttons">
                    <div class="btn-wrapper" v-for="(btn, i) in form.template.buttons" :key="i">
                      <span type="button" class="btn-link-preview" v-if="btn.type == 'PHONE_NUMBER'"><i class="fas fa-phone"></i> {{ btn.text }}</span>
                      <span type="button" class="btn-link-preview" v-if="btn.type == 'URL'"><i class="fas fa-link"></i> {{ btn.text }}</span>
                      <span type="button" class="btn-link-preview" v-if="btn.type == 'QUICK_REPLY'">{{ btn.text }}</span>
                    </div>
                  </div>
                </div>
                <p v-else class="card-text">
                  Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                  dia xxx.
                </p>
              </div>
            </div>
            <img
              class="phone-img"
              src="@/assets/phone.svg"
              alt="Phone Background"
            />
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import WhatsAppService from '@/services/whatsapp.service';
import SmsService from '@/services/sms.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import TemplateInput from '@/components/rebranding/whatsapp/TemplateInput.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

const GSM = /^[ -~\n]*$/;

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    TemplateInput,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    datePicker,
    MCrop,
    LottieAnimNoData,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/send',
      },

      Toast: {},
      file: {},
      cropPreview: {},
      cropActive: {},
      client: {},
      shortCodes: [],
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      files: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      uploadProgress: 0,
      vars: [],
      fileVariables: [],
      form: {
        type: 'simple',
        from: 'Default',
        to: '',
        body: '',
        file: '',
        normalize: false,
        flash: false,
        template: {},
        params: [],
        schedule: true,
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
    };
  },
  computed: {
    classes() {
      return ['wizard'];
    },
  },
  created() {
    this.fetchClient();
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    if (this.$route.params.contacts) {
      this.contacts = this.$route.params.contacts;
      this.contacts.forEach((contact) => {
        this.selectedContacts.push(contact.id);
      });
      this.form.type = 'contacts';
    }
    if (this.$route.params.groups) {
      this.groups = this.$route.params.groups;
      this.groups.forEach((group) => {
        this.selectedGroups.push(group.id);
      });
      this.form.type = 'groups';
    }
  },
  methods: {
    callCrop(cropPreview, maxWidthP, maxHeightP, cropActive) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    crop(data) {
      this.cropActive = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        this.file = data;
        document.getElementById(this.cropPreview).src = `${this.file.url}`;
      }
      console.log(this.file);
    },
    fetchClient() {
      Promise.all([WhatsAppService.getClient()])
        .then(
          (responses) => {
            console.log(responses);
            this.client = responses[0].data;
            this.fetched = true;
            this.form.client_id = this.client.id;
            console.log('Chamando antes...');
            this.$root.$emit('templatenew');
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetchShortCodes() {
      SmsService.getAccountShortCodes().then(
        (response) => {
          this.shortCodes = response;
          if (response[0]) {
            this.form.from = response[0].short_code;
          } else {
            this.form.from = 'Default';
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          {
            name: this.$t('generic-str.lbl-cellphone'),
            value: 'mobile_number',
          },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    addVarParams(variable, i) {
      console.log(this.form.params);
      this.form.params[
        i
      ].text = `${this.form.params[i].text}{${variable.value}}`;
      this.$forceUpdate();
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    smsTextChange() {
      let contVar = false;
      if (this.type === 'files') {
        this.fileVariables.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      } else {
        this.vars.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    changeSchedule() {},
    countSegments() {
      this.length = this.form.body.length;
    },
    insertTemplate(param) {
      if (!param) return;

      let template;

      try {
        template = JSON.parse(param);
        this.form.template = {};
        this.form.template.name = template.name;
        if (template.status !== 'approved') {
          this.$toast.show({
            title: 'WhatsApp',
            content: this.$t('warnings.send-component.teplate-not-active'),
            type: 'danger',
          });
        }
      } catch (error) {
        console.error('template', { error, param });
        this.$toast.show({
          title: 'WhatsApp',
          content: String(error),
          type: 'danger',
        });
        return;
      }
      console.log(template);
      template.components.forEach((element) => {
        console.log(element);
        this.form.template.type = 'text';
        if (element.type === 'BODY') {
          this.form.template.body = element.text;
          this.form.body = element.text;
        }
        if (element.type === 'HEADER') {
          this.form.template.header = element;
          this.form.template.type = 'rich';
          if (element.format === 'IMAGE') {
            this.form.template.type = 'image';
          }
        }
        if (element.type === 'FOOTER') {
          this.form.template.footer = element;
          this.form.template.type = 'rich';
        }
        if (element.type === 'BUTTONS') {
          this.form.template.buttons = element.buttons;
          this.form.template.type = 'rich';
        }
        // const count = (this.value.match(/{{(.*?)}}/g) || []).length;
      });
      console.log(this.form.template.buttons);
      const countVar = (this.form.template.body.match(/{{(.*?)}}/g) || [])
        .length;
      this.form.params = [];
      for (let i = 0; i < countVar; i += 1) {
        console.log(i);
        this.form.params.push({
          index: i + 1,
          text: '',
        });
      }
    },
    send() {
      this.submited = true;
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      switch (this.form.type) {
        case 'simple':
          this.form.contacts = [];
          this.form.groups = [];
          let errorMessage = null;

          if (this.form.template.body === null) {
            errorMessage = 'Informe o template';
          }

          if (this.form.params.length > 0) {
            this.form.params.forEach((param) => {
              if (
                param.text === undefined ||
                param.text === '' ||
                param.text === null
              ) {
                errorMessage = this.$t('warnings.send-component.inform-param');
              }
            });
          }

          if (this.form.to.length === 0) {
            errorMessage = this.$t('warnings.send-component.inform-recipient');
          }

          if (errorMessage) {
            this.$toast.show({
              type: 'danger',
              title: 'WhatsApp',
              content: errorMessage,
            });

            return;
          }

          if (this.form.to.length > 0 && this.form.template.body) {
            this.sendRequest();
          }
          break;
        case 'contacts':
          if (this.selectedContacts.length > 0) {
            this.form.contacts = this.selectedContacts;
            this.form.to = '';

            this.sendRequest();
          } else {
            this.$toast.show({
              type: 'danger',
              title: 'WhatsApp',
              content: this.$t('warnings.send-component.contact'),
            });
          }
          break;
        case 'groups':
          this.form.contacts = [];
          this.form.to = '';

          if (this.selectedGroups.length > 0) {
            this.form.groups = this.selectedGroups;
            this.sendRequest();
          } else {
            this.$toast.show({
              type: 'danger',
              title: 'WhatsApp',
              content: this.$t('warnings.send-component.group'),
            });
          }
          break;
        case 'files':
          if (this.form.file) {
            this.form.files = this.selectedFiles;
            this.sendRequest();
          } else {
            this.$toast.show({
              type: 'danger',
              title: 'WhatsApp',
              content: this.$t('warnings.send-component.archive'),
            });
          }
          break;
        default:
      }
    },
    sendRequest() {
      console.log(this.file);
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          if (this.form.template.header) {
            if (this.form.template.header.format === 'IMAGE') {
              this.sendMedia();
            } else {
              this.sendData();
            }
          } else {
            this.sendData();
          }
        }
      });
    },
    sendData() {
      this.isSending = true;
      console.log(JSON.stringify(this.form));
      WhatsAppService.sendText(this.form)
        .then(
          (send) => {
            console.log(JSON.stringify(send));
            /* if (this.form.type === 'simple') {
              this.$router.push({
                name: 'SuccessWhatsApp',
                params: { sms: send.data },
              });
            } else {
              this.$router.push('/whatsapp/campaigns');
            } */
            this.form = {
              type: 'simple',
              from: 'Default',
              to: '',
              body: '',
              file: '',
              normalize: false,
              flash: false,
              template: {},
              params: [],
              schedule: true,
            };
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-registered'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    sendMedia() {
      if (this.file.file !== null && this.file.file !== undefined) {
        const formData = new FormData();
        const filesCrop = [];
        filesCrop.push({
          x: this.file.x,
          y: this.file.y,
          w: this.file.width,
          h: this.file.height,
        });
        formData.append('files[]', this.file.file);
        formData.append('role', 'public');
        formData.append('folder', 'rcs');
        formData.append('crop', JSON.stringify(filesCrop));

        Promise.all([
          BucketService.upload(formData, true, this.callBackProgress),
        ])
          .then(
            (responses) => {
              console.log(responses);
              console.log(responses[0].data.url);
              this.form.template.image = responses[0].data[0].url;
              this.sendData();
            },
            (error) => {
              this.content = error;
              this.isSending = false;
              console.log(error);
            },
          )
          .finally(() => {
            this.isSending = false;
          });
      } else {
        this.form.template.image =
          this.form.template.header.example.header_handle[0];
        this.sendData();
      }
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    this.$root.$on('call.crop', this.crop);
  },
};
</script>

<style scoped lang="scss">
textarea {
  resize: none !important;
}
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
 /*  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.send-group-card {
  box-shadow: none;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.var-insede-input i {
  font-size: 15px !important;
}
/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

/* .send-opt li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
} */

.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
}

.template-style {
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .v-select::v-deep > div:first-child,
  .campaign-input {
    min-width: 320px;
    max-width: 320px;
  }
}
.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group {
  margin-bottom: 3rem;
}

::v-deep label {
  color: var(--gray-font-color-2);
  font-weight: 400;
}
::v-deep .vs__search {
  position: absolute !important;
}

.preview-div {
  display: flex;
  // position: relative;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: fit-content;
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #00000029;
}
.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}
@media (max-width: 767.98px) {
  .btn-import {
    margin-left: .5rem;
  }
}
.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}
.archive-opt {
}
.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}
@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    margin-top: 10%;
    z-index: -1;
  }
  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
  }
  .card-preview {
    position: absolute;
    width: 410px;
    // top: 200px;
    right: 20%;
    margin-top: 4rem;
    margin-bottom: 0 !important;
  }
  .card-preview .card-body {
    padding: 3rem 1rem;
  }
  .form-file {
    min-width: 320px;
    max-width: 320px;
   }
}

.calendar_form {
  position: absolute;
  right: 146px;
  width: 25px;
  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 25px;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}
.c-pointer {
  cursor: pointer !important;
}

/* Card-tabs mobile */
@media (max-width: 767.98px) {
  ::v-deep .bootstrap-datetimepicker-widget {
    right: -150px !important;
  }

  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.active span) {
    display: none;
  }
  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }
  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }
  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }
  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }

  .form-file {
    width: 80%;
  }
}
</style>
