<template>
  <div class="form-group template-div">
    <!-- <div :class="{ 'col-8': insertBtn, 'col-12': !insertBtn }"> -->
    <select
      class="form-control template-style"
      :disabled="!loaded"
      @change="changeTemplate"
      :class="{ hide: loaded && templates.length == 0 }"
    >
      <option value>{{ $t('generic-str.lbl-choose-template') }}</option>
      <option
        v-for="template in templates"
        :key="template.id"
        :value="JSON.stringify(template)"
      >
        {{ template.name }}
      </option>
    </select>
    <div v-if="!loaded" class="preloader pl-xxs pls-primary">
      <svg class="pl-circular" viewBox="25 25 50 50">
        <circle class="plc-path" cx="50" cy="50" r="20" />
      </svg>
    </div>
    <div v-if="loaded && templates.length == 0" class="alert alert-warning">
      Nenhum template ativo disponível
    </div>
    <!-- </div> -->
    <!-- <div class="col-4"> -->
    <router-link
      v-if="insertBtn"
      to="/whatsapp/templates"
      class="btn btn-yup-purple btn-icon-add"
      tag="button"
      ><span class="material-icons"> add </span></router-link
    >
    <!-- </div> -->
  </div>
</template>

<script>
import WhatsAppService from '@/services/whatsapp.service';

export default {
  props: {
    insertBtn: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      loaded: false,
      form: {
        page: 1,
        limit: 1000,
      },
      templates: {},
    };
  },
  mounted() {
    this.$root.$on('templatenew', this.callTemplates);
  },
  methods: {
    callTemplates(client) {
      console.log('Chamando');
      this.form.client_id = client;
      this.form.status = 'active';
      this.fetchTemplates();
    },
    fetchTemplates() {
      WhatsAppService.getTemplates(this.form).then(
        (response) => {
          this.loaded = true;
          this.templates = response.data.data.waba_templates;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeTemplate(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
.preloader {
  position: absolute;
  top: 5px;
  right: 35px;
}

/* Rebranding */
.template-style {
  height: 38px;
  font-size: 0.8rem;
  width: 100%;
}
.btn-icon-add {
  padding: 10px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-div {
  display: flex;
  width: inherit;
  gap: 10px;
}
@media (min-width: 768px) {
  .template-style {
    width: 320px;
  }
}
</style>
